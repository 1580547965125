// Include Functions
@import '../../bootstrap/functions';

$link-color: #7253ed;

// Include remainder of required Bootstrap stylesheets
@import '../../bootstrap/variables';
@import '../../bootstrap/variables-dark';
@import '../../bootstrap/maps';
@import '../../bootstrap/mixins';

@import '../../bootstrap/root';
@import '../../bootstrap/utilities';

@import '../../bootstrap/grid';
@import '../../bootstrap/badge';

@import '../../bootstrap/helpers';
@import '../../bootstrap/utilities/api';
